import React from "react";
import Modal, { ModalSection } from "../../components/Modal";
import {
  formatPatientDoc,
  formatTransportDoc,
  Patients as RefPatients,
  Transports as RefTransports,
} from "../../services/firebase";
import Palette from "../../styles/Palette";
import moment from "moment";
import Loading from "../../components/Loading";
import TransportList from "../../components/TransportList";
import useDataFromRef from "../../hooks/useDataFromRef";

export default function Patient({ location }) {
  const params = new URLSearchParams(location.search);
  const patientId = params.get("patient");

  const { data: patient, loading: patientLoading } = useDataFromRef({
    ref: patientId && RefPatients.doc(patientId),
    format: formatPatientDoc,
    initialState: null,
    listener: true,
    condition: patientId,
    refreshArray: [patientId],
    simpleRef: true,
  });

  const { data: transports, transportsLoading } = useDataFromRef({
    ref:
      patientId &&
      RefTransports.where("patient.id", "==", patientId).orderBy("startDate", "asc"),
    format: formatTransportDoc,
    listener: true,
    condition: patientId,
    refreshArray: [patientId],
  });

  const loading = patientLoading || transportsLoading;

  function Empty() {
    return (
      <div style={{ height: "100%", width: "100%" }} className={"containerCenter"}>
        <h1>Patient introuvable</h1>
      </div>
    );
  }

  function Patient({ containerStyle }) {
    const { firstName, lastName, gender, birthDate, socialSecurityNumber } = patient;
    const Field = ({ title, value, last = false }) => (
      <div style={{ flex: 1 }}>
        <div style={{ fontSize: 14, color: Palette.darkGrey, marginBottom: 5 }}>
          {title}
        </div>
        <div style={{ fontWeight: 500 }}>{value}</div>
      </div>
    );
    return (
      <ModalSection
        containerStyle={{
          flexDirection: "row",
          padding: 20,
          ...containerStyle,
        }}
      >
        <Field title={"Nom"} value={lastName} />
        <Field title={"Prénom"} value={firstName} />
        <Field title={"Sexe"} value={gender === "male" ? "Homme" : "Femme"} />
        <Field title={"Date de naissance"} value={moment(birthDate).format("ll")} />
        <Field title={"Numéro de sécu"} value={socialSecurityNumber} />
      </ModalSection>
    );
  }

  function Transports() {
    return (
      <div>
        <h2 style={{ marginBottom: 20 }}>Historique des transports</h2>
        <TransportList {...{ transports }} />
      </div>
    );
  }

  return (
    patientId && (
      <Modal
        onClick={close}
        title={patient && `${patient.firstName} ${patient.lastName}`}
      >
        {loading ? (
          <Loading />
        ) : patient ? (
          <>
            <Patient containerStyle={{ marginBottom: 30 }} />
            <Transports />
          </>
        ) : (
          <Empty />
        )}
      </Modal>
    )
  );
}
