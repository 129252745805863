// eslint-disable-next-line no-unused-vars
import Palette from "../../styles/Palette";
import React from "react";

import { extendMoment } from "moment-range";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import { STATUS } from "../../helpers/status";

const moment = extendMoment(Moment);

const Header = ({ days }) => {
  return (
    <div style={{ display: "flex", marginLeft: 42 }}>
      {days.map((d, i) => {
        const isToday = d.isSame(moment(), "d");
        const isLast = i === days.length - 1;
        const isFirst = i === 0;
        return (
          <div
            style={{
              width: `${100 / 7}%`,
              height: 30,
              border: `1px solid ${Palette.grey}`,
              borderTopLeftRadius: isFirst ? 5 : 0,
              borderTopRightRadius: isLast ? 5 : 0,
              borderRightWidth: isLast ? 1 : 0,
              backgroundColor: isToday ? Palette.blueTranslucent : Palette.white,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: isToday ? 500 : undefined,
            }}
            key={d.toString()}
          >
            <div style={{ marginRight: 5 }}>{d.format("ddd")}</div>
            <div>{d.format("DD")}</div>
          </div>
        );
      })}
    </div>
  );
};

const Body = ({ days, transports }) => {
  const history = useHistory();
  const hours = Array.from(moment().range("day").by("hour", { step: 2 }));

  return (
    <div
      style={{
        height: "100%",
        overflowY: "auto",
        display: "flex",
      }}
    >
      <div style={{ width: 42 }}>
        {hours.map((h, i) => (
          <div
            key={i}
            style={{
              minHeight: 24,
              height: `calc(${100 / 12}% + 1px - 0.20%)`,
              fontSize: 13,
            }}
          >
            {h.format("HH:mm")}
          </div>
        ))}
      </div>
      {days.map((day, ci) => {
        const hours = Array.from(day.range("day").by("hour", { step: 2 }));
        // const isFirstColumn = ci === 0;
        const isLastColumn = ci === days.length - 1;

        return (
          <div key={ci} style={{ flex: 1 }}>
            {hours.map((hour, ri) => {
              const hourRange = moment.range(
                hour,
                moment(hour).add(1, "h").add(59, "m").add(59, "s")
              );
              const transportForHourRange = transports.filter((t) =>
                hourRange.contains(t.startDate)
              );
              // const isToday = hour.isSame(moment(), "d");
              const isFirstRow = ri === 0;
              // const isLastRow = ri === days.length - 1;
              return (
                <div
                  style={{
                    flex: 1,
                    minHeight: 23,
                    height: `calc(${100 / 12}% - 0.25%)`,
                    border: `1px solid ${Palette.grey}`,
                    borderTopWidth: 0,
                    borderRightWidth: isLastColumn ? 1 : 0,
                    borderBottomLeftRadius: isFirstRow ? 1 : 0,
                    backgroundColor: Palette.white,
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                  }}
                  key={hour.toString()}
                  className={"hideScrollbars"}
                >
                  {transportForHourRange.map((d, i, a) => {
                    const isLast = i === a.length - 1;
                    return (
                      <div
                        onClick={() =>
                          history.push({
                            search: `?transport=${d.id}`,
                          })
                        }
                        key={i}
                        className={"containerItem"}
                        style={{
                          cursor: "pointer",
                          marginBottom: isLast ? 0 : 2,
                          borderRadius: 5,
                          backgroundColor: STATUS[d.status].style.backgroundColor,
                          display: "flex",
                          flexDirection: "column",
                          borderLeft: `3px solid ${STATUS[d.status].style.color}`,
                          margin: 2,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: 12,
                            justifyContent: "space-between",
                            height: 15,
                            margin: 2,
                          }}
                        >
                          <div style={{ fontWeight: 500 }}>
                            {moment(d.startDate).format("HH:mm")}
                          </div>
                          {d.patient && (
                            <div
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                marginLeft: 3,
                                marginRight: 3,
                              }}
                            >
                              {d.patient.firstName}
                            </div>
                          )}
                          <img
                            src={`/icons/${
                              d.carType === "AMBULANCE" ? "ambulance" : "car"
                            }.png`}
                            style={{ width: 21, height: 21 }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default function Week({ dateRange, transports }) {
  const days = Array.from(dateRange.by("day"));
  return (
    <div style={{ height: "100%" }}>
      <Header {...{ days }} />
      <Body {...{ days, transports }} />
    </div>
  );
}
