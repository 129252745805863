const Palette = {
  white: "white",
  black: "black",
  grey: "#EFEFEF",
  lightGrey: "#F8F8F8",
  darkGrey: "#8d8d8d",
  red: "#DB2C73",
  salmon: "#F28992",
  redTranslucent: "rgba(219,44,115,0.08)",
  green: "#34C759",
  greenTranslucent: "rgba(52,199,89,0.08)",
  blue: "#506BFF",
  blueTranslucent: "rgba(55,125,255,0.08)",
};

export default Palette;
