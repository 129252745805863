import Palette from "../styles/Palette";
import React from "react";

export default function ItinaryLine({ size = 7, horizontal }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: horizontal ? "row" : "column",
        alignItems: "center",
        marginTop: 3,
        marginBottom: 3,
        marginRight: horizontal ? undefined : 10,
      }}
    >
      <div
        style={{
          height: size,
          width: size,
          backgroundColor: Palette.white,
          border: `${size / 4}px solid ${Palette.blue}`,
          borderRadius: 999,
        }}
      />
      <div
        style={{
          borderBottom: `${horizontal ? size / 5 : 0}px dashed ${Palette.red}`,
          borderRight: `${!horizontal ? size / 5 : 0}px dashed ${Palette.red}`,
          height: horizontal ? 0 : undefined,
          width: horizontal ? 100 : 0,
          flex: 1,
        }}
      />
      <div
        style={{
          height: size,
          width: size,
          backgroundColor: Palette.white,
          border: `${size / 4}px solid ${Palette.blue}`,
          borderRadius: 999,
        }}
      />
    </div>
  );
}
