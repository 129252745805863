import React from "react";

import Palette from "../styles/Palette";

export default function Table({
  tableauTitle = null,
  data = [],
  renderRow,
  tableauHeaderList = [],
  containerStyle = {},
}) {
  return (
    <div style={{ ...containerStyle, height: "100%" }}>
      {tableauTitle && <h3>{tableauTitle}</h3>}

      <div
        className="containerRowSpaceBetween"
        style={{
          padding: 10,
          borderRadius: 5,
          backgroundColor: Palette.grey,
          marginBottom: 5,
        }}
      >
        {tableauHeaderList.map((title, index) => (
          <div style={{ fontSize: 13, fontWeight: 500, flex: 1 }} key={index}>
            {title.toUpperCase()}
          </div>
        ))}
      </div>

      <div
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          overflowY: "auto",
          height: "calc(100% - 50px)",
        }}
        className={"hideScrollbars"}
      >
        {data.map((item, index) => (
          <div key={index} style={{ cursor: "pointer" }}>
            {renderRow(item)}
            {index !== data.length - 1 && <div className="separatorHorizontal" />}
          </div>
        ))}
      </div>
    </div>
  );
}
