import React, { useMemo, useState } from "react";
import Modal, { ModalSection } from "../../components/Modal";
import {
  formatTransportDoc,
  formatUserDoc,
  Transports,
  Transports as RefTransports,
  Users,
} from "../../services/firebase";
import Palette from "../../styles/Palette";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import Button from "../../components/Button";
import useDataFromRef from "../../hooks/useDataFromRef";
import firebase from "firebase/compat";
import { useAuth } from "../../providers/Auth";
import ItinaryLine from "../../components/ItinaryLine";
import Global from "../../styles/Global";
import Colors from "../../styles/Colors";

export default function Transport({ location }) {
  const { uid } = firebase.auth().currentUser || {};
  const auth = useAuth();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const transportId = params.get("transport");
  const [assignMode, setAssignMode] = useState(false);

  const { data: transport, loading } = useDataFromRef({
    initialState: null,
    ref: transportId && RefTransports.doc(transportId),
    simpleRef: true,
    format: formatTransportDoc,
    listener: true,
    condition: transportId,
    refreshArray: [transportId],
  });

  const isMyTransport = useMemo(
    () => transport?.createdBy === uid,
    [transport, uid]
  );
  const isRegulator = useMemo(
    () => auth.user?.role === "REGULATOR" && transport?.regulatedBy === uid,
    [transport, uid]
  );

  const { data: collaborators } = useDataFromRef({
    initialState: [],
    ref: uid && Users.where("regulatedBy", "==", uid),
    format: formatUserDoc,
    listener: true,
    condition: assignMode,
  });

  function Empty() {
    return (
      <div style={{ height: "100%", width: "100%" }} className={"containerCenter"}>
        <h1>Transport introuvable</h1>
      </div>
    );
  }

  const isWomen = (t) => transport.patient.gender === "female" && t;

  const Patient = () => {
    const { firstName, lastName, birthDate, id } = transport.patient;
    return (
      <ModalSection row={2}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 style={{ marginBottom: 20 }}>Patient</h2>
          <Button
            onClick={() => history.push({ search: `?patient=${id}` })}
            variant="secondary"
            size="icon"
            imgLeft={"/icons/view.png"}
            iconStyle={{
              width: 24,
              height: 24,
            }}
          />
        </div>
        <div>
          <div>
            M{isWomen("me")}. {firstName} {lastName}
          </div>
          <div>
            Né{isWomen("e")} le {moment(birthDate).format("l")} (
            {moment().diff(moment(birthDate), "years")} ans)
          </div>
        </div>
      </ModalSection>
    );
  };

  const Driver = () => {
    const { carType } = transport;
    return (
      <ModalSection row={2}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 style={{ marginBottom: 20 }}>
            {carType === "VSL" ? "VSL" : "Ambulance"}
          </h2>
          {auth.user.role === "REGULATOR" &&
            transport.regulatedBy === uid &&
            transport.status === "ACCEPTED" && (
              <Button
                onClick={() => setAssignMode(true)}
                variant="secondary"
                size="icon"
                imgLeft={"/icons/edit.png"}
                iconStyle={{
                  width: 20,
                  height: 20,
                }}
              />
            )}
        </div>
        <div>
          Assigné à{" "}
          {transport?.driver
            ? `${transport.driver.firstName} ${transport.driver.lastName}`
            : "Aucun"}
        </div>
      </ModalSection>
    );
  };

  const Informations = () => {
    const { comments } = transport;
    return (
      <ModalSection row={2}>
        <h2 style={{ marginBottom: 20 }}>Informations</h2>
        <div>
          <div>{comments || "Aucun"}</div>
        </div>
      </ModalSection>
    );
  };

  const Direction = () => {
    const { from, to, startDate, endDate } = transport;

    return (
      <ModalSection row={2}>
        <h2 style={{ marginBottom: 20 }}>Trajet</h2>
        <div style={{ fontSize: 16, fontWeight: 400 }}>
          {moment(startDate).format("dddd DD MMMM YYYY [à] HH:mm")}
        </div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: Colors.text.secondary,
            marginBottom: 10,
          }}
        >
          {moment(startDate).fromNow()}
        </div>
        {false && (
          <div style={{ fontSize: 14, color: Colors.text.secondary }}>
            {moment(endDate).diff(startDate, "m")} min
          </div>
        )}
        <div className={"containerRow"}>
          <ItinaryLine size={16} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginBottom: 20 }}>
              <div style={{ fontWeight: 500 }}>{from.name}</div>
              <div style={{ fontSize: 14, color: Colors.text.secondary }}>
                {from.address}
              </div>
              <div style={{ fontSize: 14, color: Colors.text.secondary }}>
                {from.moreNotes}
              </div>
            </div>
            <div>
              <div style={{ fontWeight: 500 }}>{to.name}</div>
              <div style={{ fontSize: 14, color: Colors.text.secondary }}>
                {to.address}
              </div>
              <div style={{ fontSize: 14, color: Colors.text.secondary }}>
                {to.moreNotes}
              </div>
            </div>
          </div>
        </div>
      </ModalSection>
    );
  };

  const Assign = () => {
    return (
      <ModalSection
        onClick={() => setAssignMode(false)}
        containerStyle={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          marginBottom: 0,
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              ...Global.containerItem,
              height: "calc(100% - 20px)",
              width: 350,
              overflowY: "auto",
              position: "relative",
            }}
            className={"hideScrollbars"}
          >
            <h2 style={{ position: "sticky", marginBottom: 10 }}>
              Collaborateurs disponibles
            </h2>
            {collaborators.map((c, i) => {
              const isLast = i === collaborators.length - 1;
              const isSelected = transport?.assignedTo === c.id;
              return (
                <div
                  onClick={() =>
                    firebase
                      .firestore()
                      .collection("transports")
                      .doc(transportId)
                      .update({ assignedTo: c.id })
                  }
                  key={c.id}
                  style={{
                    cursor: "pointer",
                    ...Global.containerItem,
                    border: `2px solid ${
                      isSelected ? Palette.blue : Palette.blueTranslucent
                    }`,
                    marginBottom: isLast ? 0 : 5,
                  }}
                >
                  <div style={{ fontWeight: 500 }}>
                    {c.firstName} {c.lastName}
                  </div>
                  {false && (
                    <div style={{ fontSize: 14, color: Colors.text.secondary }}>
                      {"Disponible jusqu'à"}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </ModalSection>
    );
  };

  const Actions = () => (
    <div
      style={{
        position: "absolute",
        top: 20,
        right: 20,
        backgroundColor: Palette.white,
        padding: 10,
        display: "flex",
      }}
    >
      {isMyTransport && (
        <>
          <Button
            onClick={() =>
              history.push({ search: `?transportRequest=${transportId}` })
            }
            variant="secondary"
            size="icon"
            imgLeft={"/icons/edit.png"}
            iconStyle={{
              width: 20,
              height: 20,
            }}
            containerStyle={{
              marginRight: 10,
            }}
          />
          <Button
            onClick={() => {
              if (
                window.confirm("Êtes-vous sûr de vouloir annuler ce transport ?")
              ) {
                Transports.doc(transportId).update({ status: "CANCELLED" });
              }
            }}
            variant="secondaryError"
            size="icon"
            imgLeft={"/icons/delete.png"}
            iconStyle={{
              width: 22,
              height: 22,
            }}
          />
        </>
      )}
      {isRegulator && transport?.status === "PENDING" && (
        <>
          <Button
            onClick={() => {
              if (
                window.confirm("Êtes-vous sûr de vouloir refuser ce transport ?")
              ) {
                Transports.doc(transportId).update({
                  status: "REFUSED",
                  regulatedBy: "BOURSE",
                });
              }
            }}
            variant="secondaryError"
            size="icon"
            imgLeft={"/icons/cross_red.png"}
            iconStyle={{
              width: 18,
              height: 18,
            }}
            containerStyle={{
              backgroundColor: Palette.redTranslucent,
              marginRight: 5,
            }}
          />
          <Button
            onClick={() => {
              if (
                window.confirm("Êtes-vous sûr de vouloir accepter ce transport ?")
              ) {
                Transports.doc(transportId).update({ status: "ACCEPTED" });
              }
            }}
            variant="secondarySuccess"
            size="icon"
            imgLeft={"/icons/check_green.png"}
            iconStyle={{
              width: 18,
              height: 18,
            }}
            containerStyle={{
              backgroundColor: Palette.greenTranslucent,
              marginRight: 5,
            }}
          />
        </>
      )}
      {isRegulator && transport?.status === "ACCEPTED" && (
        <Button
          onClick={() => {
            if (window.confirm("Êtes-vous sûr de vouloir annuler ce transport ?")) {
              Transports.doc(transportId).update({
                status: "CANCELLED",
                regulatedBy: "BOURSE",
              });
            }
          }}
          variant="secondaryError"
          size="icon"
          imgLeft={"/icons/cross_red.png"}
          iconStyle={{
            width: 18,
            height: 18,
          }}
          containerStyle={{
            backgroundColor: Palette.redTranslucent,
          }}
        />
      )}
    </div>
  );

  return (
    transportId && (
      <Modal
        title={`Transport ${transportId}`}
        containerStyle={{
          flexDirection: "column",
        }}
      >
        {loading ? (
          <Loading />
        ) : transport ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {transport.patient && <Patient />}
            <Driver />
            <Direction />
            <Informations />
            <Actions />
            {assignMode && <Assign />}
          </div>
        ) : (
          <Empty />
        )}
      </Modal>
    )
  );
}
