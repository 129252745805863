import { useEffect, useState } from "react";

export default function useDataFromRef({
  ref,
  initialState = [],
  simpleRef = false,
  documentID = "id",
  listener = false,
  refreshArray = [],
  condition = true,
  format = null,
}) {
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (condition) {
      setLoading(true);
      if (listener) {
        const subData = getListenerData();
        return () => subData();
      } else {
        getData();
      }
    }
  }, [...refreshArray, condition]);

  const getData = async () => {
    try {
      let newData;

      const dataSnap = await ref.get();

      if (dataSnap.docs.length > 0) {
        if (simpleRef) {
          newData = {
            ...(format ? format(dataSnap) : dataSnap.data()),
            [documentID]: dataSnap.id,
          };
        } else {
          newData = dataSnap.docs.map((item) => {
            return {
              ...(format ? format(item) : item.data()),
              [documentID]: item.id,
            };
          });
        }

        setData(newData);
      } else {
        setData([]);
      }
    } catch (e) {
      console.log(e);
      setData([]);
    }
    setLoading(false);
  };

  const getListenerData = () => {
    return ref.onSnapshot(
      (dataSnap) => {
        let newData;

        if (simpleRef) {
          newData = {
            ...(format ? format(dataSnap) : dataSnap.data()),
            [documentID]: dataSnap.id,
          };
        } else {
          newData = dataSnap.docs.map((item) => {
            return {
              ...(format ? format(item) : item.data()),
              [documentID]: item.id,
            };
          });
        }
        setData(newData);
        setLoading(false);
      },
      (e) => {
        console.log(e);
        setData([]);
        setLoading(false);
      }
    );
  };

  return { loading, data };
}
