import React from "react";
import Palette from "../styles/Palette";
import Colors from "../styles/Colors";

const sizes = {
  big: {
    height: 58,
    fontSize: 16,
    fontWeight: "600",
  },
  small: {
    height: 44,
    fontSize: 14,
    fontWeight: "600",
  },
  icon: {
    height: 44,
    width: 44,
    padding: 0,
  },
  smallIcon: {
    height: 32,
    width: 32,
    fontSize: 24,
    padding: 0,
  },
};

const variants = {
  primary: {
    backgroundColor: Palette.blue,
    color: Palette.white,
  },
  primaryError: {
    backgroundColor: Palette.red,
    color: Palette.white,
  },
  primarySuccess: {
    backgroundColor: Palette.green,
    color: Palette.white,
  },
  primaryInfo: {
    backgroundColor: Palette.salmon,
    color: Palette.white,
  },
  secondary: {
    backgroundColor: Palette.blueTranslucent,
    color: Palette.blue,
  },
  secondarySuccess: {
    backgroundColor: Palette.greenTranslucent,
    color: Palette.green,
  },
  secondaryError: {
    backgroundColor: Palette.redTranslucent,
    color: Palette.red,
  },
  secondaryBorder: {
    backgroundColor: "transparent",
    border: `1px solid ${Colors.input.border}`,
  },
};

export default function Button({
  text,
  onClick,
  fullWidth,
  variant = "primary",
  size = "small",
  imgLeft,
  IconLeft,
  iconStyle,
  containerStyle,
}) {
  return (
    <button
      {...{ onClick }}
      style={{
        margin: 0,
        paddingLeft: 20,
        paddingRight: 20,
        width: fullWidth ? "100%" : null,
        cursor: "pointer",
        borderRadius: 5,
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sizes[size],
        ...variants[variant],
        ...containerStyle,
      }}
    >
      {imgLeft && (
        <img
          src={imgLeft}
          style={{
            width: sizes[size].fontSize,
            height: sizes[size].fontSize,
            marginRight: text ? 10 : 0,
            ...iconStyle,
          }}
        />
      )}
      {IconLeft && (
        <IconLeft
          color={variants[variant].color}
          size={sizes[size].fontSize}
          style={{ marginRight: text ? 10 : 0, ...iconStyle }}
        />
      )}
      {text}
    </button>
  );
}
