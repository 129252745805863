// eslint-disable-next-line no-unused-vars
const prodConfig = {
  algolia: {
    appId: "DR80QPO2RC",
    apiKey: "cc0ae54c9159b2dc955ddef8bdb437a3",
  },
};

// eslint-disable-next-line no-unused-vars
const devConfig = {
  algolia: {
    appId: "D5H943VWHD",
    apiKey: "9ca8ea4b53fcb949edb2ed4c95328bd7",
  },
};

exports.config = prodConfig;
