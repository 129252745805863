import React from "react";
import Palette from "../../../styles/Palette";
import { REASONS } from "../../../data/transports";
import Colors from "../../../styles/Colors";
import moment from "moment";
import Global from "../../../styles/Global";
import TransportResume from "../../../components/TransportResume";
import ComplementResume from "../../../components/Previews/ComplementPreview";

export default ({
  transport: { patient, transportInformations, from, to, complement },
}) => {
  return (
    <div className={"container"} style={{ alignItems: "center" }}>
      <div style={{ marginBottom: 50 }}>
        <div
          style={{
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Récapitulatif
        </div>
        {transportInformations?.reason && (
          <div
            className={"containerRow"}
            style={{ fontSize: 13, justifyContent: "center" }}
          >
            <div
              style={{
                color: Colors.text.secondary,
                marginRight: 5,
              }}
            >
              Prise en charge pour
            </div>
            <div
              style={{
                color: Palette.red,
                fontWeight: 500,
              }}
            >
              {REASONS.find((t) => t.value === transportInformations.reason)?.name}
            </div>
          </div>
        )}
      </div>
      <div className={"containerRow"}>
        <div className={"flex"} style={{ marginRight: 30 }}>
          <TransportResume
            transport={{ ...transportInformations, from, to, patient }}
          />
        </div>
        <div className={"flex"}>
          <div style={{ ...Global.box, marginBottom: 15 }}>
            <div
              className={"containerRow"}
              style={{ alignItems: "center", marginBottom: 10 }}
            >
              <div style={{ fontSize: 17, fontWeight: 500, marginRight: 10 }}>
                Patient :
              </div>
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: Palette.red,
                }}
              >
                {patient.gender === "male" ? "M" : "Mme"} {patient.firstName}{" "}
                {patient.lastName}
              </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div className={"containerRow"} style={{ fontSize: 14 }}>
                <div style={{ color: Colors.text.secondary, marginRight: 5 }}>
                  Adresse:
                </div>
                {patient.address?.address}, {patient.address?.postalCode}{" "}
                {patient.address?.city}
              </div>
              {patient.address?.complement && (
                <div className={"containerRow"} style={{ fontSize: 14 }}>
                  <div style={{ color: Colors.text.secondary, marginRight: 5 }}>
                    Complément:
                  </div>
                  {patient.address.complement}
                </div>
              )}
            </div>
            <div>
              <div className={"containerRow"} style={{ fontSize: 14 }}>
                <div style={{ color: Colors.text.secondary, marginRight: 5 }}>
                  Date de naissance:
                </div>
                {moment(patient.birthDate).format("l")}
              </div>
              {patient.socialSecurityNumber && (
                <div className={"containerRow"} style={{ fontSize: 14 }}>
                  <div style={{ color: Colors.text.secondary, marginRight: 5 }}>
                    N°SS:
                  </div>
                  {patient.socialSecurityNumber}
                </div>
              )}
              {patient.phoneNumber && (
                <div className={"containerRow"} style={{ fontSize: 14 }}>
                  <div style={{ color: Colors.text.secondary, marginRight: 5 }}>
                    Tel:
                  </div>
                  {patient.phoneNumber}
                </div>
              )}
            </div>
          </div>
          <ComplementResume transport={complement} />
        </div>
      </div>
      {transportInformations.returnDate && (
        <div
          className={"containerRow"}
          style={{
            ...Global.box,
            alignItems: "center",
            marginTop: 30,
            fontSize: 18,
          }}
        >
          <div
            style={{
              fontWeight: 500,
              color: Palette.red,
              marginRight: 20,
            }}
          >
            Transport Retour
          </div>
          <div style={{ fontWeight: 700, marginRight: 20 }}>
            {moment(transportInformations.returnDate).format("dddd DD MMMM")}
          </div>
          <img
            src={`/icons/${
              transportInformations.carType === "AMBULANCE" ? "ambulance" : "car"
            }.png`}
            style={{ width: 30, height: 30 }}
          />
        </div>
      )}
    </div>
  );
};
