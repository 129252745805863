import Global from "../styles/Global";
import Palette from "../styles/Palette";
import moment from "moment";
import ItinaryLine from "./ItinaryLine";
import Colors from "../styles/Colors";
import React, { forwardRef, useEffect, useState } from "react";
import TransportStatusTag from "./TransportStatusTag";
import { REASONS } from "../data/transports";
import Input from "./Input";
import DatePicker from "react-datepicker";

const DateInput = forwardRef(({ onClick: onFocus, ...props }, ref) => (
  <Input {...{ onFocus, ref, ...props }} />
));

export default ({
  transport,
  containerStyle,
  variant = "FORM",
  editable,
  onChange = () => {},
  onChangeDem = () => {},
}) => {
  const { from, to } = transport || { from: {}, to: {} };
  const [startDate, setStartDate] = useState(
    transport?.startDate || moment().toDate()
  );
  const [demDate, setDemDate] = useState(
    transport?.demDate || transport?.startDate || moment().toDate()
  );

  useEffect(() => {
    onChange(startDate);
  }, [startDate]);

  useEffect(() => {
    onChangeDem(demDate);
  }, [demDate]);

  return (
    <div style={{ ...Global.box, ...containerStyle }}>
      <div
        className={"containerRow"}
        style={{ marginBottom: 15, justifyContent: "space-between" }}
      >
        <div>
          <div style={{ fontSize: 18, fontWeight: 500, color: Palette.red }}>
            Aller
          </div>
          <div style={{ fontSize: 18, fontWeight: 700 }}>
            {moment(transport.startDate).format("dddd DD MMMM")}
          </div>
          {variant === "MODAL" && (
            <div
              className={"containerRow"}
              style={{ fontSize: 13, justifyContent: "center" }}
            >
              <div
                style={{
                  color: Colors.text.secondary,
                  marginRight: 5,
                }}
              >
                Prise en charge pour
              </div>
              <div
                style={{
                  color: Palette.red,
                  fontWeight: 500,
                }}
              >
                {REASONS.find((t) => t.value === transport.reason)?.name}
              </div>
            </div>
          )}
          {transport?.isUrgent && (
            <div style={{ fontSize: 11, fontWeight: 500, color: Palette.red }}>
              !! PRISE EN CHARGE URGENTE !!
            </div>
          )}
        </div>
        <div>
          {variant === "FORM" ? (
            <div
              className={"container"}
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={`/icons/${
                  transport.carType === "AMBULANCE" ? "ambulance" : "car"
                }.png`}
                style={{ width: 30, height: 30 }}
              />
              <div style={{ fontSize: 13, textAlign: "center" }}>
                {transport.carType === "AMBULANCE" ? "Ambulance" : "VSL"}
              </div>
            </div>
          ) : (
            <TransportStatusTag status={transport.status} />
          )}
        </div>
      </div>
      {variant === "MODAL" && editable && (
        <div className={"containerRowCenter"} style={{ marginBottom: 15 }}>
          <span style={{ fontSize: 12, color: Colors.text.secondary }}>
            Heure de démarrage :&nbsp;
          </span>
          <div>
            <DatePicker
              required
              locale="fr"
              dateFormat="HH:mm"
              showTimeSelect
              showTimeSelectOnly
              onChange={setDemDate}
              selected={demDate}
              placeholder="Heure"
              customInput={
                <DateInput
                  required
                  style={{ height: 20, width: 42 }}
                  inputStyle={{ fontSize: 12, textAlign: "center" }}
                  inputContainerStyle={{ padding: 0 }}
                />
              }
            />
          </div>
        </div>
      )}
      <div className={"containerRow"} style={{ minHeight: 200 }}>
        <div
          className={"container"}
          style={{
            marginRight: 20,
            justifyContent: "space-between",
          }}
        >
          {editable ? (
            <div className={"containerCenter"}>
              <div style={{ fontSize: 12, color: Colors.text.secondary }}>PEC</div>
              <DatePicker
                required
                locale="fr"
                dateFormat="HH:mm"
                showTimeSelect
                showTimeSelectOnly
                onChange={setStartDate}
                selected={startDate}
                placeholder="Heure"
                customInput={
                  <DateInput
                    required
                    style={{ height: 20, width: 42 }}
                    inputStyle={{ fontSize: 12, textAlign: "center" }}
                    inputContainerStyle={{ padding: 0 }}
                  />
                }
              />
            </div>
          ) : (
            <div
              style={{
                fontSize: 17,
                fontWeight: 500,
                color: Palette.red,
              }}
            >
              {moment(transport.startDate).format("HH:mm")}
            </div>
          )}
          {editable ? (
            <div className={"containerCenter"}>
              <div style={{ fontSize: 12, color: Colors.text.secondary }}>RDV</div>
              <Input
                value={moment(transport.endDate).format("HH:mm")}
                style={{ height: 20, width: 42 }}
                inputStyle={{
                  fontSize: 12,
                  textAlign: "center",
                  color: Colors.text.secondary,
                }}
                inputProps={{ disabled: true }}
                inputContainerStyle={{ padding: 0 }}
              />
            </div>
          ) : (
            <div
              style={{
                fontSize: 17,
                fontWeight: 500,
                color: Palette.red,
              }}
            >
              {moment(transport.endDate).format("HH:mm")}
            </div>
          )}
        </div>
        <ItinaryLine size={16} />
        <div
          className={"container"}
          style={{ marginLeft: 10, justifyContent: "space-between" }}
        >
          <div>
            <div style={{ fontSize: 17 }}>{from.name}</div>
            <div style={{ fontSize: 14, color: Colors.text.secondary }}>
              {from.address}, {from.postalCode} {from.city}
            </div>
            {from.phoneNumber && (
              <div style={{ fontSize: 13, color: Colors.text.secondary }}>
                Tel: {from.phoneNumber}
              </div>
            )}
            {(from.service ||
              from.complement ||
              from.establishment ||
              from.chamber) && (
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 10,
                  color: Palette.red,
                  fontSize: 14,
                }}
              >
                {from.service && <div>{from.service}</div>}
                {from.complement && <div>{from.complement}</div>}
                {from.establishment && <div>{from.establishment}</div>}
                {from.chamber && <div>{from.chamber}</div>}
              </div>
            )}
          </div>

          <div style={{ marginTop: 20 }}>
            <div style={{ fontSize: 17 }}>{to.name}</div>
            <div style={{ fontSize: 14, color: Colors.text.secondary }}>
              {to.address}, {to.postalCode} {to.city}
            </div>
            {to.phoneNumber && (
              <div style={{ fontSize: 14, color: Colors.text.secondary }}>
                Tel: {to.phoneNumber}
              </div>
            )}
            {(to.service || to.complement || to.establishment || to.chamber) && (
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 10,
                  color: Palette.red,
                  fontSize: 14,
                }}
              >
                {to.service && <div>{to.service}</div>}
                {to.complement && <div>{to.complement}</div>}
                {to.establishment && <div>{to.establishment}</div>}
                {to.chamber && <div>{to.chamber}</div>}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
