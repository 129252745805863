import React from "react";
import TransportDetailsCard from "./TransportDetailsCard";
import Palette from "../styles/Palette";
import moment from "moment";
import TransportCard from "./TransportCard";
import Colors from "../styles/Colors";

export default function TransportList({
  transports = [],
  type = "details",
  onClick,
  containerStyle,
  cardStyle,
  selected,
}) {
  const Component = type === "details" ? TransportDetailsCard : TransportCard;
  return (
    <div style={containerStyle}>
      {transports.map((d, i, a) => {
        const prevStartDate = i > 0 ? transports[i - 1].startDate : null;
        const isSameAsPrevDay =
          prevStartDate && moment(prevStartDate).isSame(d.startDate, "d");
        const isLast = i === a.length - 1;
        const isSelected = selected && selected.id === d.id;
        return (
          <div key={d.id} style={{ position: "relative" }}>
            {!isSameAsPrevDay && (
              <div
                style={{
                  fontSize: 17,
                  fontWeight: 500,
                  marginBottom: 5,
                  marginTop: i !== 0 ? 5 : 0,
                }}
              >
                {moment(d.startDate).format("dddd DD MMMM YYYY")}
              </div>
            )}
            <Component
              {...{ onClick }}
              transport={d}
              key={i}
              containerStyle={{
                border: `1px solid ${
                  !isSelected ? Colors.input.border : Palette.blueTranslucent
                }`,
                marginBottom: isLast ? 0 : 10,
                ...cardStyle,
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
