import Palette from "../styles/Palette";
import Colors from "../styles/Colors";
import React from "react";

export default ({ onSelect, selected = "VSL", containerStyle }) => {
  const driverTypes = [
    { text: "VSL", value: "VSL", icon: "/icons/car" },
    { text: "Ambulance", value: "AMBULANCE", icon: "/icons/ambulance" },
  ];
  return (
    <div style={{ display: "flex" }}>
      {driverTypes.map(({ text, icon, value }, i) => {
        const isSelected = value === selected;
        const isLast = i === driverTypes.length - 1;
        return (
          <div
            className={"flex"}
            onClick={() => onSelect(value)}
            key={i}
            style={{
              cursor: "pointer",
              height: 66,
              borderRadius: 2.5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginRight: isLast ? 0 : 10,
              border: `2px solid ${isSelected ? Palette.blue : Colors.input.border}`,
              backgroundColor: isSelected ? Palette.blueTranslucent : Palette.white,
              ...containerStyle,
            }}
          >
            <img
              src={`${icon}${isSelected ? "_blue" : ""}.png`}
              style={{ width: 32, height: 32 }}
            />
            <div
              style={{
                fontSize: 17,
                fontWeight: 500,
                color: isSelected ? Palette.blue : undefined,
              }}
            >
              {text}
            </div>
          </div>
        );
      })}
    </div>
  );
};
