import React from "react";
import Palette from "../styles/Palette";
import ItinaryLine from "./ItinaryLine";
import { useHistory } from "react-router-dom";
import Colors from "../styles/Colors";
import moment from "moment";

export default function TransportCard({
  transport: { id, startDate, endDate, from, to, patient, driver, carType },
  transport,
  containerStyle,
  onClick,
}) {
  const history = useHistory();

  return (
    <div
      onClick={() => {
        onClick
          ? onClick(transport)
          : history.push({
              search: `?transport=${id}`,
            });
      }}
      style={{
        cursor: "pointer",
        backgroundColor: Palette.white,
        borderRadius: 5,
        padding: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...containerStyle,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <div className={"containerRowCenter"}>
          <img
            src={`/icons/user.png`}
            style={{ width: 21, height: 21, marginRight: 5 }}
          />
          <div style={{ fontSize: 13 }}>
            {patient?.firstName} {patient?.lastName}
          </div>
        </div>
        <div className={"containerRowCenter"}>
          <img
            src={`/icons/${carType === "AMBULANCE" ? "ambulance" : "car"}.png`}
            style={{ width: 21, height: 21, marginRight: 5 }}
          />
          <div style={{ fontSize: 13, textAlign: "center" }}>
            {driver?.firstName} {driver?.lastName}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginRight: 10,
              fontSize: 15,
              fontWeight: 500,
            }}
          >
            <div>{moment(startDate).format("HH:mm")}</div>
            <div>{moment(endDate).format("HH:mm")}</div>
          </div>
          <ItinaryLine size={10} />
          <div style={{ marginRight: 10 }}>
            <div style={{ fontSize: 15 }}>{from.name}</div>
            <div
              style={{ color: Colors.text.secondary, fontSize: 13, marginBottom: 5 }}
            >
              {from.address}
            </div>
            <div style={{ fontSize: 15 }}>{to.name}</div>
            <div style={{ color: Colors.text.secondary, fontSize: 13 }}>
              {to.address}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
