import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// eslint-disable-next-line no-unused-vars
const prodConfig = {
  apiKey: "AIzaSyA_HTIrUbSHVhPQntFwf5V15gv5oy5uqPE",
  authDomain: "ambulances-6d7b8.firebaseapp.com",
  projectId: "ambulances-6d7b8",
  storageBucket: "ambulances-6d7b8.appspot.com",
  messagingSenderId: "21747993760",
  appId: "1:21747993760:web:bdffb58851ddaa50f90dc5",
};

// eslint-disable-next-line no-unused-vars
const devConfig = {
  apiKey: "AIzaSyCPZc90OyAzvblVufIQX9gkCsKx0Rx-ZJA",
  authDomain: "hostal-dev.firebaseapp.com",
  projectId: "hostal-dev",
  storageBucket: "hostal-dev.appspot.com",
  messagingSenderId: "373364930067",
  appId: "1:373364930067:web:858983691e37f63ae8f1e6",
};

firebase.initializeApp(prodConfig);

// firebase.initializeApp(
//   process.env.NODE_ENV !== "production" ? devConfig : prodConfig
// );

export const firebaseErrors = (code) => {
  switch (code) {
    case "auth/provider-already-linked":
      return "Ce compte est déjà lié à cet utilisateur.";
    case "auth/invalid-credential":
      return "Identifiants incorrects.";
    case "auth/credential-already-in-use":
      return "Ce compte existe déjà ou est déjà lié.";
    case "auth/email-already-in-use":
      return "Ce compte existe déjà.";
    case "auth/operation-not-allowed":
      return "Le provider n'est pas activé.";
    case "auth/invalid-email":
      return "Email invalide.";
    case "auth/wrong-password":
      return "Mot de passe incorrect.";
    case "auth/invalid-verification-code":
      return "Code de vérification incorrect.";
    case "auth/invalid-verification-id":
      return "Impossible de vous authentifié, réessayez dans quelques secondes.";
    case "auth/invalid-phone-number":
      return "Numéro de téléphone incorrect.";
    case "auth/too-many-requests":
      return "Nous avons détecté une activité inhabituelle et avons bloqué momentanément votre requête, réessayez dans quelques minutes.";
    default:
      return "Erreur inconnue";
  }
};

export const Transports = firebase.firestore().collection("transports");
export const Patients = firebase.firestore().collection("patients");
export const Users = firebase.firestore().collection("users");

export const formatDoc = (doc) => {
  return { ...doc.data(), id: doc.id };
};

export const formatTransportDoc = (doc) => {
  const toReturn = {
    id: doc.id,
    ...doc.data(),
    startDate: doc.data().startDate.toDate(),
    endDate: doc.data().endDate.toDate(),
    demDate: doc.data().demDate?.toDate(),
  };
  if (toReturn?.patient?.birthDate) {
    toReturn.patient.birthDate = toReturn.patient.birthDate.toDate();
  }
  return toReturn;
};

export const formatPatientDoc = (doc) => ({
  id: doc.id,
  ...doc.data(),
  birthDate: doc.data().birthDate.toDate(),
});

export const formatUserDoc = (doc) => ({
  id: doc.id,
  ...doc.data(),
});

export async function getTransport(id) {
  try {
    const snap = await Transports.doc(id).get();
    return formatTransportDoc(snap);
  } catch (e) {
    console.log(e);
  }
}

export async function updateTransport(id, transport) {
  return await Transports.doc(id).update(transport);
}

export async function addTransport(transport, regulatedBy, transportId) {
  const { uid } = firebase.auth().currentUser;
  if (transportId) {
    return await Transports.doc(transportId).set({
      ...transport,
      createdBy: uid,
      regulatedBy,
      status: "PENDING",
    });
  } else {
    return await Transports.add({
      ...transport,
      createdBy: uid,
      regulatedBy,
      status: "PENDING",
    });
  }
}

export async function getPatient(id) {
  try {
    const snap = await Patients.doc(id).get();
    return formatPatientDoc(snap);
  } catch (e) {
    console.log(e);
  }
}

export async function addPatient(patient) {
  const { uid } = firebase.auth().currentUser;
  return await Patients.add({ ...patient, createdBy: uid });
}

export async function getUser(id) {
  try {
    const snap = await Users.doc(id).get();
    return formatUserDoc(snap);
  } catch (e) {
    console.log(e);
  }
}

export async function getRegulatorFor(id) {
  try {
    const regulatorSnap = await Users.doc(id).get();
    if (regulatorSnap.exists) {
      return formatUserDoc(regulatorSnap);
    } else return null;
  } catch (e) {
    console.log(e);
  }
}
