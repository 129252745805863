import React from "react";
import LayoutMain from "../../../layouts/main";
import Global from "../../../styles/Global";
import Table from "../../../components/Table";
import {
  formatPatientDoc,
  Patients as RefPatients,
} from "../../../services/firebase";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Palette from "../../../styles/Palette";
import firebase from "firebase/compat";
import useDataFromRef from "../../../hooks/useDataFromRef";
import Loading from "../../../components/Loading";

export function PatientList({ patients, containerStyle, onClick, selected }) {
  const history = useHistory();

  return (
    <div
      style={{
        ...Global.containerItem,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        ...containerStyle,
      }}
    >
      <Table
        tableauHeaderList={[
          "Nom",
          "Prénom",
          "Sexe",
          "Date de naissance",
          "Numéro de sécu",
        ]}
        data={patients}
        renderRow={(patient) => {
          const {
            id,
            lastName,
            firstName,
            gender,
            birthDate,
            socialSecurityNumber,
          } = patient;
          const isSelected = selected && id === selected.id;
          return (
            <div
              onClick={() =>
                onClick
                  ? onClick(patient)
                  : history.push({ search: `?patient=${id}` })
              }
              style={{
                display: "flex",
                marginLeft: -10,
                marginRight: -10,
                padding: 8,
                borderRadius: 5,
                border: `2px solid ${isSelected ? Palette.blue : "transparent"}`,
              }}
            >
              <div style={{ flex: 1 }}>{lastName}</div>{" "}
              <div style={{ flex: 1 }}>{firstName}</div>{" "}
              <div style={{ flex: 1 }}>{gender === "male" ? "Homme" : "Femme"}</div>
              <div style={{ flex: 1 }}>{moment(birthDate).format("ll")}</div>
              <div style={{ flex: 1 }}>{socialSecurityNumber}</div>
            </div>
          );
        }}
      />
    </div>
  );
}

export default function Patients() {
  const { uid } = firebase.auth().currentUser;
  const { data: patients, loading } = useDataFromRef({
    ref: RefPatients.where("createdBy", "==", uid),
    format: formatPatientDoc,
    listener: true,
  });

  return (
    <LayoutMain>
      <PatientList {...{ patients }} />
      {loading && <Loading absolute />}
    </LayoutMain>
  );
}
