import React from "react";
import Colors from "../styles/Colors";
import Palette from "../styles/Palette";

export default ({ value, onChange, name, style }) => {
  const isSelected = !!value;
  return (
    <div
      onClick={() => onChange(!value)}
      className={"containerRow"}
      style={{ cursor: "pointer", alignItems: "center", ...style }}
    >
      <div
        style={{
          width: 19,
          height: 19,
          borderRadius: 5,
          border: `1px solid ${Colors.input.border}`,
          backgroundColor: Palette.white,
          marginRight: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isSelected && (
          <div
            style={{
              width: "66%",
              height: "66%",
              backgroundColor: Palette.blue,
              borderRadius: 2.5,
            }}
          />
        )}
      </div>
      <div style={{ fontSize: 14 }}>{name}</div>
    </div>
  );
};
