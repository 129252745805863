import {
  addPatient,
  addTransport,
  Patients,
  updateTransport,
} from "../services/firebase";
import firebase from "firebase/compat";

export default async ({
  patient,
  transportInformations,
  from,
  to,
  complement,
  regulatedBy,
  transportId,
}) => {
  const transport = { ...transportInformations, ...complement, patient, from, to };
  let returnTransport = null;
  // Check if patient have to be created
  if (!patient.id) {
    const { id } = await addPatient(patient);
    patient.id = id;
  } else {
    await Patients.doc(patient.id).update(patient);
  }

  // Migrate new to old
  if (
    !transport.from.address.includes(
      `${transport.from.postalCode} ${transport.from.city}`
    )
  ) {
    transport.from.address = `${transport.from.address}, ${transport.from.postalCode} ${transport.from.city}`;
  }
  if (
    !transport.to.address.includes(`${transport.to.postalCode} ${transport.to.city}`)
  ) {
    transport.to.address = `${transport.to.address}, ${transport.to.postalCode} ${transport.to.city}`;
  }

  if (transportId !== "new") {
    console.log("update transport id", transportId);
    await updateTransport(transportId, transport);
  } else {
    if (transportInformations.returnDate) {
      const { returnDate, returnEndDate } = Object.assign({}, transport);
      delete transport.returnDate;
      delete transport.returnEndDate;
      returnTransport = {
        ...transport,
        startDate: returnDate,
        endDate: returnEndDate,
        from: to,
        to: from,
      };
    }
    if (returnTransport) {
      const transportId = firebase.firestore().collection("transports").doc().id;
      const returnTransportId = firebase
        .firestore()
        .collection("transports")
        .doc().id;
      await addTransport(
        { ...transport, returnTransportId },
        regulatedBy,
        transportId
      );
      await addTransport(
        { ...returnTransport, returnTransportId: transportId },
        regulatedBy,
        returnTransportId
      );
    } else {
      await addTransport(transport, regulatedBy);
    }
  }
};
