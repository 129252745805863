export const REASONS = [
  { name: "Consultation", value: "CONSULT", icon: "/icons/addressTypes/doctor.png" },
  {
    name: "Hospitalisation",
    value: "HOSPITALISATION",
    icon: "/icons/addressTypes/establishment.png",
  },
  {
    name: "Retour Domicile",
    value: "BACK_TO_HOME",
    icon: "/icons/addressTypes/home.png",
  },
  { name: "Autre", value: "OTHER" },
];

export const COMPANIONS = [
  { name: "Oncle", value: "Oncle" },
  { name: "Grand mère", value: "Grand mère" },
];

export const ADDITIONAL_NEEDS = [
  { name: "Oxygène", value: "OXYGEN", icon: "/icons/additionalNeeds/oxygen.png" },
  {
    name: "Brancard bariatrique",
    value: "STRETCHER",
    icon: "/icons/additionalNeeds/stretcher.png",
  },
  {
    name: "Fauteuil roulant",
    value: "WHEELCHAIR",
    icon: "/icons/additionalNeeds/wheelchair.png",
  },
  { name: "Autre", value: "OTHER" },
];
