import "./styles/App.css";
import React, { useEffect } from "react";
import WebFont from "webfontloader";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ProvideAuth, useAuth } from "./providers/Auth";
import { Toaster } from "react-hot-toast";
import moment from "moment";
import "moment/locale/fr";
import Login from "./pages/auth/Login";

// HOSPITAL
import HospitalDashboard from "./pages/main/Hospital/Dashboard";
import Patients from "./pages/main/Hospital/Patients";
import Transports from "./pages/main/Transports";

// REGULATOR
import RegulatorDashboard from "./pages/main/Regulator/Dashboard";

// MODAL
import Transport from "./pages/main/Transport";
import Patient from "./pages/main/Patient";
import TransportRequest from "./pages/main/TransportRequest";

moment.locale("fr");

function Dashboard() {
  const auth = useAuth();
  return auth.user.role === "HOSPITAL" ? HospitalDashboard() : RegulatorDashboard();
}

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Inter:400,500,600,700"],
      },
    });
  }, []);

  return (
    <ProvideAuth>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <PrivateRoute path="/dashboard">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/patients">
            <Patients />
          </PrivateRoute>
          <PrivateRoute path="/transports">
            <Transports />
          </PrivateRoute>
        </Switch>
        <PrivateRoute path="/" component={Transport} />
        <PrivateRoute path="/" component={Patient} />
        <PrivateRoute path="/" component={TransportRequest} />
      </BrowserRouter>
      <Toaster />
    </ProvideAuth>
  );
}

function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
