import React, { createContext, useContext, useEffect, useState } from "react";
import { getRegulatorFor, getUser } from "../services/firebase";
import firebase from "firebase/compat/app";
import Loading from "../components/Loading";

const authContext = createContext(undefined);

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {!auth.authenticating ? children : <Loading absolute />}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [authenticating, setAuthenticating] = useState(true);

  const initialize = () => {
    return firebase.auth().onAuthStateChanged(async (u) => {
      if (u) {
        const userData = await getUser(u.uid);
        if (userData.role === "HOSPITAL") {
          userData.regulator = await getRegulatorFor(userData.regulatedBy);
        }
        setUser(userData);
      } else {
        setUser(null);
      }
      if (authenticating) {
        setAuthenticating(false);
      }
    });
  };

  useEffect(() => {
    const sub = initialize();
    return () => sub();
  }, []);

  const signIn = async ({ email, password }) => {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signOut = async () => {
    await firebase.auth().signOut();
  };

  return {
    authenticating,
    user,
    signIn,
    signOut,
  };
}
