import Global from "../../styles/Global";
import { ADDITIONAL_NEEDS } from "../../data/transports";
import Colors from "../../styles/Colors";
import React from "react";

export default ({ transport, containerStyle }) => {
  return (
    <div style={{ ...Global.box, ...containerStyle }}>
      <div className={"containerRow"} style={{ justifyContent: "space-between" }}>
        <div className={"container flex"}>
          <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 20 }}>
            À prévoir
          </div>
          {transport?.additionalNeeds?.map((c, i) => {
            const isLast = transport?.additionalNeeds?.length === i + 1;
            const need = ADDITIONAL_NEEDS.find((n) => n.value === c);
            return (
              <div
                key={need.value}
                className={"containerRow"}
                style={{
                  marginBottom: isLast ? 0 : 10,
                  alignItems: "center",
                }}
              >
                <img
                  src={need.icon}
                  style={{ width: 30, height: 30, marginRight: 10 }}
                />
                <div style={{ fontSize: 13, color: Colors.text.secondary }}>
                  {need.name}
                </div>
              </div>
            );
          })}
        </div>
        <div className={"container flex"} style={{ alignItems: "flex-end" }}>
          <div
            className={"container"}
            style={{
              marginBottom: 20,
              alignItems: "flex-end",
            }}
          >
            <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 5 }}>
              Accompagnateur(s)
            </div>
            {transport?.companions?.map((c) => (
              <div
                key={c.value}
                style={{ color: Colors.text.secondary, fontSize: 13 }}
              >
                {c}
              </div>
            ))}
          </div>
          <div className={"container"} style={{ alignItems: "flex-end" }}>
            <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 5 }}>
              Informations
            </div>
            <div
              style={{
                color: Colors.text.secondary,
                fontSize: 13,
                textAlign: "right",
              }}
            >
              {transport?.moreNotes}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
