import { STATUS } from "../helpers/status";
import React from "react";

export default ({ status }) => {
  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: 600,
        padding: 5,
        borderRadius: 5,
        fontSize: 15,
        ...STATUS[status].style,
      }}
    >
      {STATUS[status].name}
    </div>
  );
};
