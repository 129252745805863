import Palette from "../styles/Palette";

export const STATUS = {
  PENDING: {
    name: "En attente",
    style: {
      backgroundColor: Palette.blueTranslucent,
      color: Palette.blue,
    },
  },
  ACCEPTED: {
    name: "Accepté",
    style: {
      backgroundColor: Palette.greenTranslucent,
      color: Palette.green,
    },
  },
  REFUSED: {
    name: "Refusé",
    style: {
      backgroundColor: Palette.redTranslucent,
      color: Palette.red,
    },
  },
  CANCELLED: {
    name: "Annulé",
    style: {
      backgroundColor: Palette.redTranslucent,
      color: Palette.red,
    },
  },
  ENDED: {
    name: "Terminé",
    style: {
      backgroundColor: Palette.greenTranslucent,
      color: Palette.green,
    },
  },
};
