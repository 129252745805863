import React from "react";
import "./style.css";
import TabBar from "../../components/TabBar";
import { useMediaQuery } from "react-responsive";

export default function LayoutMain({ containerStyle = {}, children, noPadding }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const calcGutters = isTabletOrMobile ? 50 : 100;
  const gutters = !noPadding ? calcGutters : 0;

  return (
    <div
      style={{
        height: isTabletOrMobile && !noPadding ? undefined : "100vh",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(0deg, #FBFBFD 33.93%, #F5F6FA 100%)",
        ...containerStyle,
      }}
    >
      <TabBar {...{ gutters: calcGutters }} />
      <div
        style={{
          position: "relative",
          marginLeft: gutters,
          marginRight: gutters,
          marginTop: !noPadding ? 20 : 0,
          marginBottom: !noPadding ? 20 : 0,
          height: "100%",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
}
