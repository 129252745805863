import React, { useEffect, useState } from "react";
import LayoutMain from "../../../layouts/main";
import GoogleMapReact from "google-map-react";
import useDataFromRef from "../../../hooks/useDataFromRef";
import {
  formatTransportDoc,
  formatUserDoc,
  Transports,
  Users,
} from "../../../services/firebase";
import firebase from "firebase/compat";
import Loading from "../../../components/Loading";
import Global from "../../../styles/Global";
import Palette from "../../../styles/Palette";
import Button from "../../../components/Button";
import { useHistory } from "react-router-dom";
import TransportList from "../../../components/TransportList";
import { toast } from "react-hot-toast";
import moment from "moment";
import SegmentedControl from "../../../components/SegmentedControl";

const NewMap = ({
  users,
  transport,
  showAvailableUsers,
  showOnInterventionUsers,
  onAssign,
}) => {
  const history = useHistory();
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();

  function drawItinary({ directionsDisplay, directionsService }) {
    return new Promise((resolve, reject) => {
      directionsDisplay.setMap(map);
      const origin = {
        lat: transport.from.location.latitude,
        lng: transport.from.location.longitude,
      };
      const destination = {
        lat: transport.to.location.latitude,
        lng: transport.to.location.longitude,
      };

      directionsService.route(
        {
          origin,
          destination,
          travelMode: maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(result);
            resolve();
          } else {
            console.error(`error fetching directions ${result}`);
            reject(new Error("error"));
          }
        }
      );
    });
  }

  async function handleMap({ directionsDisplay, directionsService }) {
    const bounds = new maps.LatLngBounds();
    if (transport) {
      await drawItinary({ directionsDisplay, directionsService });
      bounds.extend(
        new maps.LatLng(
          transport.from.location.latitude,
          transport.from.location.longitude
        )
      );
      bounds.extend(
        new maps.LatLng(
          transport.to.location.latitude,
          transport.to.location.longitude
        )
      );
    }
    users.forEach((user) => {
      if (showAvailableUsers && user.interventionOn) return null;
      bounds.extend(
        new maps.LatLng(user.location.latitude, user.location.longitude)
      );
      return null;
    });
    map.fitBounds(bounds);
  }

  useEffect(() => {
    if (map && maps && users?.length > 0) {
      const directionsService = new maps.DirectionsService();
      const directionsDisplay = new maps.DirectionsRenderer({
        polylineOptions: { strokeWeight: 6, strokeColor: Palette.blue, zIndex: 99 },
      });
      handleMap({ directionsDisplay, directionsService });
      return () => directionsDisplay.setMap(null);
    }
  }, [map, maps, users, transport, showAvailableUsers]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: "AIzaSyA_HTIrUbSHVhPQntFwf5V15gv5oy5uqPE",
      }}
      defaultCenter={[46.71109, 1.7191036]}
      defaultZoom={7}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        setMap(map);
        setMaps(maps);
      }}
    >
      {users.map((u) => {
        return (
          <div
            onClick={() => showAvailableUsers && onAssign(transport, u)}
            style={{
              position: "relative",
              zIndex: !u.interventionOn ? 1 : 0,
              cursor: showAvailableUsers && "pointer",
            }}
            key={u.id}
            lat={u.location.latitude}
            lng={u.location.longitude}
          >
            <img
              src={"/icons/carHorizontal.png"}
              width={62.5}
              height={32}
              style={{
                position: "absolute",
                left: -31.25,
              }}
            />
            {!u.interventionOn && showAvailableUsers && (
              <div
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: 10,
                  left: -100,
                  right: -100,
                  borderRadius: 5,
                  padding: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  ...Global.bigShadow,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: 16, fontWeight: 500 }}>
                    {u.firstName} {u.lastName}
                  </div>
                  {false && (
                    <div style={{ fontSize: 14, color: Palette.darkGrey }}>
                      {"Disponible jusqu'à"}
                    </div>
                  )}
                </div>
              </div>
            )}
            {u.interventionOn && showOnInterventionUsers && (
              <div
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: 10,
                  left: -100,
                  right: -100,
                  borderRadius: 5,
                  padding: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  ...Global.bigShadow,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: 16, fontWeight: 500 }}>
                    {u.firstName} {u.lastName}
                  </div>
                  <div style={{ fontSize: 14, color: Palette.darkGrey }}>
                    En intervention
                  </div>
                </div>
                <Button
                  onClick={() =>
                    history.push({
                      search: `?transport=${u.interventionOn}`,
                    })
                  }
                  variant="secondaryBorder"
                  size="icon"
                  imgLeft={"/icons/chevronLeft.png"}
                  iconStyle={{
                    width: 24,
                    height: 24,
                    transform: "rotate(180deg)",
                  }}
                  containerStyle={{
                    width: 40,
                    height: 40,
                  }}
                />
              </div>
            )}
          </div>
        );
      })}

      <div
        style={{
          position: showAvailableUsers ? "absolute" : "relative",
          zIndex: 0,
          top: "-100%",
          left: "-100%",
          bottom: 0,
          right: 0,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
      />
    </GoogleMapReact>
  );
};

export default function Dashboard() {
  const { uid } = firebase.auth().currentUser;
  const [transportSelected, setTransportSelected] = useState(null);
  const [transportToAssign, setTransportToAssign] = useState(null);
  const [type, setType] = useState(0);
  const { data: users, loading } = useDataFromRef({
    ref: Users.where("role", "==", "course").where("regulatedBy", "==", uid),
    format: formatUserDoc,
    listener: true,
  });
  const { data: transports } = useDataFromRef({
    ref: Transports.where("regulatedBy", "==", uid)
      .where("status", "==", "PENDING")
      .where("startDate", ">=", moment().toDate())
      .orderBy("startDate", "asc"),
    format: formatTransportDoc,
    listener: true,
  });
  // eslint-disable-next-line no-unused-vars
  const { data: bourseTransports } = useDataFromRef({
    ref: Transports.where("regulatedBy", "==", "BOURSE")
      .where("startDate", ">=", moment().toDate())
      .orderBy("startDate", "asc"),
    format: formatTransportDoc,
    listener: true,
  });

  return (
    <LayoutMain noPadding>
      <NewMap
        {...{ users }}
        showAvailableUsers={!!transportToAssign}
        showOnInterventionUsers={!transportSelected}
        transport={transportSelected}
        onAssign={(transport, user) => {
          Transports.doc(transport.id).update({
            status: "ACCEPTED",
            assignedTo: user.id,
          });
          setTransportSelected(null);
          setTransportToAssign(null);
          toast.success(`Course acceptée & assignée à ${user.firstName}`);
        }}
      />
      <div
        style={{
          zIndex: 10,
          position: "absolute",
          right: 10,
          top: "20%",
          bottom: 10,
          width: 350,
          backgroundColor: "white",
          borderRadius: 10,
          padding: 10,
          ...Global.bigShadow,
        }}
      >
        <div style={{ height: 80 }}>
          <h1 style={{ marginBottom: 10 }}>Transports à réguler</h1>
          <SegmentedControl
            items={[{ text: "En attente" }, { text: "Bourse" }]}
            selected={type}
            onSelect={setType}
          />
        </div>
        <div
          style={{ overflowY: "auto", height: "calc(100% - 80px)" }}
          className={"hideScrollbars"}
        >
          <TransportList
            {...{
              transports: type === 0 ? transports : bourseTransports,
              selected: transportSelected,
            }}
            type={"normal"}
            onClick={(t) => {
              setTransportSelected(t);
              setTransportToAssign(null);
            }}
          />
        </div>
      </div>
      {transportSelected && (
        <div
          style={{
            zIndex: 10,
            position: "absolute",
            right: 390,
            top: "calc(20% + 90px)",
            width: 200,
            backgroundColor: "white",
            borderRadius: 10,
            padding: 10,
            ...Global.bigShadow,
          }}
        >
          <Button
            onClick={() => {
              Transports.doc(transportSelected.id).update({
                status: "ACCEPTED",
                regulatedBy: uid,
              });
              setTransportSelected(null);
              toast.success("Course acceptée");
            }}
            fullWidth
            text="Accepter la course"
            variant={"secondarySuccess"}
            containerStyle={{ marginBottom: 10 }}
          />
          <Button
            onClick={() => {
              // Transports.doc(transportSelected.id).update({ status: "ACCEPTED" });
              setTransportToAssign(transportSelected);
            }}
            fullWidth
            text="Assigner la course"
            variant={"secondary"}
            containerStyle={{ marginBottom: 10 }}
          />
          {transportSelected.regulatedBy !== "BOURSE" && (
            <Button
              onClick={() => {
                Transports.doc(transportSelected.id).update({
                  status: "REFUSED",
                  regulatedBy: "BOURSE",
                });
                setTransportSelected(null);
                toast.success("Course refusée");
              }}
              fullWidth
              text="Refuser cette course"
              variant={"secondaryError"}
              containerStyle={{ marginBottom: 20 }}
            />
          )}
          <Button
            onClick={() => {
              setTransportSelected(null);
              setTransportToAssign(null);
            }}
            fullWidth
            text="Annuler"
            variant={"primary"}
          />
        </div>
      )}
      {loading && <Loading absolute />}
    </LayoutMain>
  );
}
