import Palette from "../styles/Palette";
import React from "react";
import moment from "moment";
import ItinaryLine from "./ItinaryLine";
import { useHistory } from "react-router-dom";
import { STATUS } from "../helpers/status";
import Colors from "../styles/Colors";

function Status({ status }) {
  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: 600,
        padding: 5,
        borderRadius: 5,
        fontSize: 15,
        ...STATUS[status].style,
      }}
    >
      {STATUS[status].name}
    </div>
  );
}

export default function TransportDetailsCard({
  transport: { id, startDate, endDate, from, to, patient, driver, carType },
  transport,
  onClick,
  containerStyle,
}) {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        onClick
          ? onClick(transport)
          : history.push({
              search: `?transport=${id}`,
            });
      }}
      style={{
        minHeight: 50,
        cursor: "pointer",
        backgroundColor: Palette.white,
        borderRadius: 3,
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...containerStyle,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "70%", marginRight: 10 }}>
          <div className={"containerRow"} style={{ marginRight: 10 }}>
            <div style={{ marginRight: 20, width: "30%" }}>
              <div style={{ fontSize: 17, fontWeight: 500, marginBottom: 5 }}>
                {moment(startDate).format("HH:mm")}
              </div>
              <div style={{ fontSize: 17 }}>{from.name}</div>
              <div style={{ fontSize: 14, color: Colors.text.secondary }}>
                {from.address}
              </div>
            </div>
            <ItinaryLine horizontal size={16} />
            <div style={{ marginLeft: 20, width: "30%" }}>
              <div style={{ fontSize: 17, fontWeight: 500, marginBottom: 5 }}>
                {moment(endDate).format("HH:mm")}
              </div>
              <div style={{ fontSize: 17 }}>{to.name}</div>
              <div style={{ fontSize: 14, color: Colors.text.secondary }}>
                {to.address}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 10,
          }}
        >
          {patient && (
            <img
              src={`/icons/user.png`}
              style={{ width: 30, height: 30, marginBottom: 9 }}
            />
          )}
          <div style={{ fontSize: 15, textAlign: "center" }}>
            {patient?.firstName} {patient?.lastName}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 10,
          }}
        >
          <img
            src={`/icons/${carType === "AMBULANCE" ? "ambulance" : "car"}.png`}
            style={{ width: 30, height: 30, marginBottom: 5 }}
          />
          <div style={{ fontSize: 15, textAlign: "center" }}>
            {driver?.firstName} {driver?.lastName}
          </div>
        </div>
        {transport.status && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1.5,
            }}
          >
            <Status status={transport.status} />
          </div>
        )}
      </div>
    </div>
  );
}
