import Button from "../Button";
import React, { useEffect, useState } from "react";
import { extendMoment } from "moment-range";
import { formatTransportDoc, Transports } from "../../services/firebase";
import Loading from "../Loading";
import useDataFromRef from "../../hooks/useDataFromRef";
import firebase from "firebase/compat";
import Week from "./Week";

import Moment from "moment";

const moment = extendMoment(Moment);

export default function Calendar() {
  const defaultDateRange = [moment().range("month"), moment().range("week")];

  const { uid } = firebase.auth().currentUser;
  const [granularity] = useState(1);
  const [dateRange, setDateRange] = useState(defaultDateRange[granularity]);
  const GranularityComponent = [Week, Week][granularity];

  useEffect(() => {
    setDateRange(defaultDateRange[granularity]);
  }, [granularity]);

  const prevWeek = () =>
    setDateRange((d) => moment(d.start.subtract(1, "d")).range("week"));
  const nextWeek = () =>
    setDateRange((d) => moment(d.end.add(1, "d")).range("week"));

  const { data: transports, loading } = useDataFromRef({
    ref: Transports.where("createdBy", "==", uid)
      .where("startDate", ">=", dateRange.start.toDate())
      .where("startDate", "<=", dateRange.end.toDate())
      .orderBy("startDate"),
    format: formatTransportDoc,
    listener: true,
    refreshArray: [dateRange],
  });

  return (
    <div style={{ height: "100%" }}>
      <div style={{ height: 60 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="secondaryBorder"
                size="icon"
                onClick={prevWeek}
                imgLeft={"/icons/chevronLeft.png"}
                iconStyle={{
                  width: 30,
                  height: 30,
                }}
              />
              <h3
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  width: 175,
                  textAlign: "center",
                }}
              >
                {dateRange.start.format("DD MMM")} - {dateRange.end.format("DD MMM")}
              </h3>
              <Button
                variant="secondaryBorder"
                size="icon"
                onClick={nextWeek}
                imgLeft={"/icons/chevronLeft.png"}
                iconStyle={{ width: 30, height: 30, transform: "rotate(180deg)" }}
              />
            </div>
          </div>
          {/*
          <ButtonGroup
            items={[{ text: "Mois" }, { text: "Semaine" }]}
            selected={granularity}
            onSelect={setGranularity}
            containerStyle={{ marginBottom: 10 }}
          />
          */}
        </div>
      </div>
      <div style={{ height: "calc(100% - 90px)", position: "relative" }}>
        <GranularityComponent {...{ dateRange, transports }} />
        {loading && <Loading absolute />}
      </div>
    </div>
  );
}
