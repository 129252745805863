import React, { useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Button from "./Button";
import Global from "../styles/Global";
import Palette from "../styles/Palette";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../providers/Auth";

export default function TabBar({ gutters = 50 }) {
  const auth = useAuth();
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const routes = useMemo(
    () => [
      {
        route: "/dashboard",
        name: "Dashboard",
        icon: "/icons/planning.png",
        condition: true,
      },
      {
        route: "/patients",
        name: "Patients",
        icon: "/icons/user.png",
        condition: auth.user?.role === "HOSPITAL",
      },
      {
        route: "/transports",
        name: "Transports",
        icon: "/icons/ambulance.png",
        condition: true,
      },
      // { route: "/calendar", name: "Calendrier", icon: "/icons/calendar.png" },
    ],
    [auth.user?.role]
  );

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "#FBFBFB",
        height: 80,
        width: "100%",
        display: "flex",
        alignItems: "center",
        ...Global.shadow,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          marginLeft: gutters,
          marginRight: gutters,
        }}
      >
        <div style={{ flex: 1 }}>
          <img
            onClick={async () => auth.signOut()}
            style={{
              cursor: "pointer",
              height: 42,
            }}
            src="/logoFull.png"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 3,
          }}
        >
          {routes
            .filter((r) => r.condition)
            .map((r) => {
              // const isLast = i === routes.length - 1
              const match = useRouteMatch({
                path: r.route,
                exact: false,
              });
              return (
                <div
                  className={"buttonHover"}
                  onClick={() => history.push(r.route)}
                  key={r.route}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    fontSize: 14,
                    fontWeight: "500",
                    alignItems: "center",
                    color: match ? Palette.blue : null,
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingBottom: 10,
                    paddingTop: 10,
                    borderRadius: 5,
                  }}
                >
                  <img
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: !isTabletOrMobile ? 8 : 0,
                    }}
                    src={match ? `${r.icon.slice(0, -4)}_blue.png` : r.icon}
                  />
                  {!isTabletOrMobile ? r.name : null}
                </div>
              );
            })}
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {auth.user?.role === "HOSPITAL" && (
            <Button
              onClick={() => history.push({ search: `?transportRequest=new` })}
              imgLeft={"/icons/plus.png"}
              text={!isTabletOrMobile ? "Nouveau transport" : null}
            />
          )}
        </div>
      </div>
    </div>
  );
}
