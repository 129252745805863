import React, { useMemo, useState } from "react";
import LayoutMain from "../../layouts/main";
import Global from "../../styles/Global";
import Moment from "moment";
import { extendMoment } from "moment-range";
import Button from "../../components/Button";
import {
  formatTransportDoc,
  Transports as RefTransports,
} from "../../services/firebase";
import TransportList from "../../components/TransportList";
import firebase from "firebase/compat";
import useDataFromRef from "../../hooks/useDataFromRef";
import Loading from "../../components/Loading";
import { useAuth } from "../../providers/Auth";

const moment = extendMoment(Moment);
const defaultDateRange = moment().range("week");

export default function Transports() {
  const auth = useAuth();
  const { uid } = firebase.auth().currentUser;
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const filters = useMemo(
    () =>
      auth.user.role === "HOSPITAL"
        ? RefTransports.where("createdBy", "==", uid)
            .where("startDate", ">=", dateRange.start.toDate())
            .where("startDate", "<=", dateRange.end.toDate())
            .orderBy("startDate")
        : RefTransports.where("regulatedBy", "==", uid)
            .where("startDate", ">=", dateRange.start.toDate())
            .where("startDate", "<=", dateRange.end.toDate())
            .orderBy("startDate"),
    [auth.user.role, dateRange]
  );

  const { data: transports, loading } = useDataFromRef({
    ref: filters,
    format: formatTransportDoc,
    listener: true,
    refreshArray: [dateRange],
  });

  const prevWeek = () =>
    setDateRange((d) => moment(d.start.subtract(1, "d")).range("week"));
  const nextWeek = () =>
    setDateRange((d) => moment(d.end.add(1, "d")).range("week"));

  function Empty() {
    return (
      <div style={{ height: "100%", width: "100%" }} className={"containerCenter"}>
        <h1>Aucun transport</h1>
      </div>
    );
  }

  return (
    <LayoutMain>
      <div
        style={{
          ...Global.containerItem,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div style={{ height: 60 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                onClick={prevWeek}
                variant="secondaryBorder"
                size="icon"
                imgLeft={"/icons/chevronLeft.png"}
                iconStyle={{
                  width: 30,
                  height: 30,
                }}
              />
              <h3
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  width: 175,
                  textAlign: "center",
                }}
              >
                {dateRange.start.format("DD MMM")} - {dateRange.end.format("DD MMM")}
              </h3>
              <Button
                onClick={nextWeek}
                variant="secondaryBorder"
                size="icon"
                imgLeft={"/icons/chevronLeft.png"}
                iconStyle={{ width: 30, height: 30, transform: "rotate(180deg)" }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            overflowY: "auto",
            height: "calc(100% - 80px)",
            position: "relative",
          }}
          className={"hideScrollbars"}
        >
          {transports.length > 0 ? <TransportList {...{ transports }} /> : <Empty />}
          {loading && <Loading absolute />}
        </div>
      </div>
    </LayoutMain>
  );
}
